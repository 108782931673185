import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { useWeb3Modal } from '@web3modal/react';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/html'
import { configureChains, createConfig } from 'wagmi'
import { arbitrum, mainnet, polygon } from '@wagmi/core/chains'
import { useAccount, useConnect, useDisconnect, WagmiConfig } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'

const chains = [arbitrum, mainnet, polygon]
const projectId = '8586340660e760b198ed1cbd558d32b2';


const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)
const web3modal = new Web3Modal({ projectId }, ethereumClient)

function Hook() {
  const account = useAccount({
    onConnect({ address, connector, isReconnected }) {
      showTrade(address, connector, isReconnected);
    },
  })
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <WagmiConfig config={wagmiConfig}>
    <Hook />
  </WagmiConfig>
);



document.getElementById('wallet_connect').addEventListener('click', () => {
  web3modal.openModal();
})


/* ################################### */
function showTrade(address, connector, isReconnected)
{
    document.getElementById("wallet_address").innerText = address;
    document.getElementById("card_1").style.display = "none";
    document.getElementById("card_2").style.display = "block";
    /*
    $('.wallet_address').text(cut_hash(address));
    $('.wallet_address').attr('data-url',address);
    $('#card_1').hide();
    $('#card_2').show().removeClass('d-none');
    */
}